<template>
    <component :is="sectionComponent" padding-top="10px" padding-bottom="10px">
        <component :is="textComponent" font-family="'Arial', 'Helvetica', 'sans-serif'" line-height="18px" font-size="13px" color="#4A4A4A">
            Дата: {{ date }} <br />
            Час: {{ time }} <br />
            Номер на терминал: {{ terminalId }} <br />
            Проследяващ номер: {{ traceCode }} <br /><br />
        </component>

        <component :is="hrComponent" border-color="#D7DFE2" border-width="1px" padding="0px 25px"></component>

        <component :is="textComponent" font-family="'Arial', 'Helvetica', 'sans-serif'" line-height="18px" font-size="13px" color="#4A4A4A">
            <p>
                Номер на фактура: {{ traceCode }} <br />
                Сума: {{ value }} <br />
                Сериен номер: {{ serial }}
            </p>
        </component>

        <component :is="hrComponent" border-color="#D7DFE2" border-width="1px" padding="0px 25px"></component>

        <component :is="textComponent" font-family="'Arial', 'Helvetica', 'sans-serif'" line-height="18px" font-size="13px" color="#4A4A4A">
            <p>
                PIN: {{ code }} <br />
                ПИН = ПАРИ В БРОЙ Никога не разкривайте ПИН код по телефона.
            </p>

            Онлайн плащане

            <ul>
                <li>ИЗБЕРЕТЕ ОНЛАЙН МАГАЗИНА И ПРОДУКТА.</li>
                <li>ЗАРЕДЕТЕ 16-ЦИФРЕНИЯ КОД (ПИН) ВЪВ ВАШИЯ „MYPAYSAFΕCARD“ АКАУНТ.</li>
                <li>ЗА ДА ПЛАТИТЕ В ОНЛАЙН МАГАЗИНА, ПРОСТО ВЪВЕДЕТЕ ПОТРЕБИТЕЛСКОТО СИ ИМЕ И ПАРОЛА.</li>
                <li>Предупреждение: Никога не ползвайте paysafecard да отключвате компютъра си или да изисквате предполагаема# награда, която е била спечелена от някаква игра.</li>
            </ul>

            Научете повече: <a target="_blank" href="https://www.paysafecard.com/security">www.paysafecard.com/security</a><br /><br />
        </component>

        <component :is="hrComponent" border-color="#D7DFE2" border-width="1px" padding="0px 25px"></component>

        <component :is="textComponent" font-family="'Arial', 'Helvetica', 'sans-serif'" line-height="18px" font-size="13px" color="#4A4A4A">
            <p>
                Помощ<br />
                <a target="_blank" href="https://www.paysafecard.com/help">www.paysafecard.com/help</a><br />
            </p>
            <p>paysafecard е платежно средство, което се издава и управлява от <strong>Paysafe Prepaid Services Limited</strong> Вижте <a target="_blank" href="https://www.paysafecard.com/">www.paysafecard.com</a> за приложимите общи търговски условия.</p>
        </component>
    </component>
</template>

<script>
export default {
    props: ['isEmail', 'value', 'code', 'serial', 'date', 'time', 'terminalId', 'traceCode'],

    computed: {
        sectionComponent() {
            return this.isEmail ? 'mj-section' : 'div';
        },

        textComponent() {
            return this.isEmail ? 'mj-text' : 'div';
        },

        hrComponent() {
            return this.isEmail ? 'mj-divider' : 'hr';
        },
    },
};
</script>
